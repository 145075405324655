(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ajuntaments.newAjuntament.controller:NewAjuntamentCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.ajuntaments.newAjuntament')
  .controller('NewAjuntamentCtrl', NewAjuntamentCtrl);

  function NewAjuntamentCtrl(Ajuntaments, towns, $state, $mdToast, $filter) {
    var vm = this;
    vm.ctrlName = 'NewAjuntamentCtrl';
    vm.ajuntament = {};

    vm.towns = towns;

    vm.addAjuntament = function () {
      Ajuntaments.save(vm.ajuntament, function () {
        $state.go('home.ajuntaments', {}, {reload: true});

        $mdToast.show(
          $mdToast.simple()
          .textContent($filter('translate')('TOWN_HALL_SUCCESSFUL_CREATE'))
          .position('bottom left')
          .hideDelay(3000)
        );
      }, function (error) {
        var errorMessage;
        if (error.status === 409) {
          errorMessage = 'ALREADY_EXISTING_TOWN_HALL';
        } else if (error.status === 403) {
          errorMessage = 'NO_PERMISSIONS';
        } else {
          errorMessage = 'SERVER_ERROR';
        }
        $mdToast.show(
          $mdToast.simple()
          .textContent($filter('translate')(errorMessage))
          .position('bottom left')
          .hideDelay(3000)
        );
      });
    };
  }
}());
